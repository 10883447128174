import About from './About';
import './App.css';
import Editpad from './Editpad';
// import nightModeCSS from './nightMode.css';
// SEO
import {Helmet} from 'react-helmet';

// GA
import ReactGA from 'react-ga';
const TRACKING_ID = "G-YDLQQJ18T9"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


function App() {


    return (
        <div>
            <Helmet>
            <title>editpad.tronlab.in</title>
            <meta name="description" content="Editpad ~ Simple easy notepad"/>
            <meta name="keywords" content="online text editor, online wordpad, textpad online, editing text online, editpad online"/>
            </Helmet>
            <Editpad/>
            <About/>
        </div>
    );
}

export default App;
