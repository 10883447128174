import React from 'react'
import './About.css';


export default function About() {
    return (
        <div>
            <h1>editpad.tronalb.in</h1>
            <h4>A product of <a href="https://tronlab.in">
                    tronlab.in</a>
            </h4>
            <h2>Write Notes at your peace of mind</h2>

            <ul>
                <li>Automatically save your text while you write, so even if you accidentally close the tab or your device shuts down, you can recover your last written note.</li>
                <li>Copy your notes with just one click.</li>
                <li>Convert your notes into multiple formats with just one click.</li>
                <li>Download your notes as a file to keep them safe and secure.</li>
                <li>Auto-capitalizes words for you and includes other helpful writing features to make the note-taking process more efficient.</li>
            </ul>


            <p>
                In today's fast-paced world, online text editors have become an essential tool for anyone who needs to create or edit text-based content. An online text editor is a web-based application that allows users to create, edit, and save documents in real-time using a web browser. They offer a convenient and flexible way to create, edit and collaborate on text-based documents from any device with an internet connection.
                                                                
                                                                Online text editors come with a wide range of features that can help users to create and edit high-quality documents.
                <br/>

                Some of the most common features include formatting options such as bold, italic, and underline, font selection, font size, text alignment, color schemes, and more. Users can also add images, videos, and other multimedia elements to their documents to make them more engaging and interactive.
                                                                
                                                                One of the key advantages of online text editors is that they are available from anywhere, at any time. They can be accessed from any device with an internet connection, whether it be a desktop computer, laptop, tablet, or smartphone. This makes it easy for users to work on their documents from anywhere, at any time, without having to worry about carrying around a physical copy of the document or needing access to a particular device.<br/>

                Another advantage of online text editors is that they are easy to use. Most online text editors have a simple and intuitive interface that makes it easy for even inexperienced users to create and edit high-quality documents. They also come with a variety of templates and pre-designed layouts that users can use to quickly create professional-looking documents without having to spend hours designing them from scratch.
                                                                
                                                                Online text editors also offer a number of collaborative features that make it easy for multiple users to work on the same document at the same time. This can be particularly useful for teams that need to collaborate on a document, such as a project proposal or a group report. Users can collaborate in real-time, adding comments, suggestions, and revisions to the document as they work.
                                                                
                                                                One of the most popular uses of online text editors is for content creation and blogging. Online text editors offer bloggers and content creators an easy way to create and edit high-quality content that can be published directly to their website or blog. With features such as automatic save and cloud storage, users can rest assured that their work is always safe and secure, even if they experience a technical issue or lose their device.
                                                                
                                                                Online text editors are also commonly used for academic purposes. Students can use online text editors to create and edit essays, research papers, and other academic documents. With features such as spell-check and grammar check, online text editors can help students to improve the quality of their writing and avoid common errors.
                                                                
                                                                Online text editors are also popular among businesses for creating and editing various types of documents, such as proposals, reports, and memos. With the collaborative features of online text editors, teams can work together on these documents, adding comments and revisions in real-time to create a final product that is both professional and accurate.
                                                                
                                                                Another popular use of online text editors is for personal use. Users can use online text editors to create and edit personal documents such as resumes, cover letters, and personal statements. With a wide range of formatting options and templates available, users can create professional-looking documents that can help them to stand out in a competitive job market.
                                                                
                                                                In conclusion, online text editors have become an essential tool for anyone who needs to create, edit, and collaborate on text-based documents. With their wide range of features, ease of use, and accessibility from anywhere, at any time, online text editors have become the go-to tool for content creators, academics, businesses, and individuals alike. Whether you are looking to create a professional document, collaborate with a team, or simply create a personal document, an online text editor is the perfect tool for the job.
            </p>

        </div>
    )
}
