import {useState, useEffect, useRef} from 'react';
import nightModeCSS from './nightMode.css';


function Editpad() {
    const [text, setText] = useState('');
    const [isNightMode, setIsNightMode] = useState(false);
    const [autoCapitalize, setAutoCapitalize] = useState(false);
    const textAreaRef = useRef(null);

    useEffect(() => {
        const savedText = localStorage.getItem('notepad-text');
        if (savedText) {
            setText(savedText);
        }
    }, []);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (autoCapitalize) {
            setText((text) => text.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
        }
    }, [autoCapitalize]);

    const handleTextChange = (event) => {
        setText(event.target.value);
        localStorage.setItem('notepad-text', event.target.value);
    };

    const handleNightModeToggle = () => {
        setIsNightMode(!isNightMode);
        console.log("NIGHT MODE ");
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(text);
    };

    const handleDownload = () => {
        const element = document.createElement('a');
        const file = new Blob([text], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = 'notepad.txt';
        document.body.appendChild(element);
        element.click();
    };

    const handleAutoCapitalizeToggle = () => {
        setAutoCapitalize(!autoCapitalize);
    };

    const handleCaseChange = (caseType) => {
        switch (caseType) {
            case 'camel':
                setText((text) => text.split(' ').map((word, index) => index === 0 ? word.charAt(0).toLowerCase() + word.slice(1) : word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
                break;
            case 'lower':
                setText(text.toLowerCase());
                break;
            case 'upper':
                setText(text.toUpperCase());
                break;
            default:
                break;
        }
    };

    // reset
    const handleReset = () => {
        setText('');
        localStorage.removeItem('notepad-text');
    };

    return (
        <div className={
            isNightMode ? 'notepad night-mode' : 'notepad'
        }>
            <textarea placeholder='Write notes..'
                ref={textAreaRef}
                value={text}
                onChange={handleTextChange}
                style={
                    {lineHeight: '1.5em'}
            }></textarea>
            <p>Word count: {
                text.trim().split(/\s+/).length
            }</p>
            <button onClick={handleNightModeToggle}>
                {
                isNightMode ? 'Day Mode' : 'Night Mode'
            } </button>
            <button onClick={handleCopy}>Copy</button>
            <button onClick={handleDownload}>Download</button>
            <button onClick={handleReset}
                className="btnReset">Reset</button>

            <label>
                <input type="checkbox"
                    checked={autoCapitalize}
                    onChange={handleAutoCapitalizeToggle}/>
                Auto Capitalize
            </label>
            <br/>
            <button onClick={
                () => handleCaseChange('camel')
            }>Camel Case</button>
            <button onClick={
                () => handleCaseChange('lower')
            }>All Lower Case</button>
            <button onClick={
                () => handleCaseChange('upper')
            }>All Upper Case</button>
            {
            isNightMode && <link rel="stylesheet" type="text/css"
                href={nightModeCSS}/>
        } </div>
    );
}

export default Editpad;
